/*======================================
//--//-->   Blog Pages
======================================*/

.blue-banner {
  background: #3F87F5;
  margin-top: 70px;
  padding: 8vh 15px 0;
  position: relative;

  h1 {
    font-weight: 800;
    font-size: 39px;
    margin-bottom: 0;
    line-height: 47px;
    color: #FFFFFF;
  }

  .input-button {
    width: 321px;
    margin-left: auto;
    margin-right: 0;
    margin-top: 30px;

    .input-group input.form-control {
      border: none;
      height: 50px;
    }

    .input-group-append button.btn.btn-outline-secondary {
      background: #D9DAE7;
      height: 50px;
      border: none;
    }
  }
}

.blue-banner.height-auto {
  height: auto !important;
}

.blue-banner.bg-light-blue {
  background: #3F87F5 !important;
}

section.last-pinned-post {
  margin-top: -50px;

  .last-post-content {
    z-index: 300;
  }
}

.blogs-recent,
.blogs-main-vist,
.blogs-new-post {
  .blog-user-info .user-image {
    margin-right: 10px;
    height: 100%;
  }
}

.blog-feedlist-wrapper {
  min-height: 196px;
}

.text-deep-blue {
  color: #0652DD;
}

.avatar {
  border-radius: 50%;
  height: auto;
  max-height: 80px;
}

.author-block {
  img.avatar {
    height: 90px;
    width: 90px;
    min-width: 90px;
    max-width: none;
  }
}

.flyier {
  background: #428DFF;
  position: relative;
  padding: 20px;

  p {
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .input-group {
    input.form-control {
      border: none;
      height: 40px;
    }

    input.form-control.narrow {
      height: 30px !important;
    }

    width: 452px;
    height: 40px;
    margin-top: 12px;
  }

  img {
    position: absolute;
    top: -44px;
    left: -38px;
  }

  .flyier-area {
    padding: 0 40px;
  }
}

.blog-feed-list {
  margin-top: 0;
  margin-bottom: 40px;
}

.post-content {
  padding-bottom: 25px;

  &:hover {
    clear: both;

    .title-content {
      h3 {
        color: #3F87F5;
      }

      h2 {
        color: #3F87F5;
      }
    }

    .post-content-image {
      min-height: 200px;

      .image-content {
        box-shadow: 0px 0px 12px 0px #a2d8fc;
        -ms-box-shadow: 0px 0px 12px 0px #a2d8fc;

        img {
          overflow: hidden;
          -ms-transform: scale(1.05) translateZ(0);
          transform: scale(1.05) translateZ(0);
        }
      }
    }
  }
}

.blue-banner h1.blog-index-heading {
  color: #0652DD;
  font-size: 50px;
  line-height: 61px;
}

.plyr--audio .plyr__controls {
  color: #FFF !important;
  background: #3F87F5 !important;
  border-radius: 10rem !important;
  padding: 1rem !important;
}

@media screen and (max-width: 765px) {
  .blogs-recent {

    .post-content,
    .col-md-6 {
      height: auto;
    }

    .title {
      min-height: 106px;
    }
  }

  .blue-banner h1.blog-index-heading {
    font-size: 25px;
    line-height: 30px;
  }

  .blue-banner .gsc-input-box {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.blogs-recent {
  .title {
    min-height: 81px;

    p {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #FFFFFF;
    }

    .title-category {
      text-transform: uppercase;
      letter-spacing: .1em;
      font-weight: 700;
      padding: 3px 0;
      width: min-content;
      position: relative;
      color: #010549;
      font-size: 12px;
      margin-bottom: 8px;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #3B424D;
    }
  }

  .title-content {
    h3 {
      font-weight: bold;
      font-size: 17px;
      line-height: 1.5;
      color: #010549;
      margin-bottom: 0;
      transition: color 0.3s ease-in-out 0s;
    }

    h2 {
      font-weight: bold;
      font-size: 17px;
      line-height: 1.5;
      color: #010549;
      margin-bottom: 0;
      transition: color 0.3s ease-in-out 0s;
    }
  }

  .post-content-image {
    .image-content {
      overflow: hidden;
      -ms-transition: box-shadow .3s ease-in-out;
      transition: box-shadow .3s ease-in-out;

      img {
        width: 100%;
        object-fit: cover;
        height: 200px;
        -ms-transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
      }
    }
  }

  .title {
    bottom: 0px;
    padding: 12px 0;
    width: 100%;

    &:before {
      content: "";
      height: 105px;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .post-text {
    p {
      font-size: 14px;
      font-weight: 400;
      color: #3B424D;
    }
  }

  .blog-user-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    height: 50px;

    .user-name {
      .h4 {
        font-weight: 600;
        font-size: 11px;
        line-height: 18px;
        color: #3B424D;
        margin-bottom: 0;
      }

      p.post-info-subtle {
        color: #a5acb7;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}



.aside {
  margin-top: 80px;
  padding-left: 30px;
  padding-right: 0;

  .follow-us {
    margin-bottom: 50px;
  }
}

.btn-recommended-posts {
  color: #0652DD;
  background-color: #D9E7FD;
  border: 0;
  font-weight: 600;
  -ms-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  min-width: 313px;

  &:hover {
    color: #FFFFFF;

    background: #3F87F5;
  }
}

.categories-btn {
  a.black-btn {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: .1em;
    line-height: 15px;
    text-transform: uppercase;
    color: #0652DD;
    background: #D9E7FD;
    border-radius: 4px;
    display: inline-block;
    width: 100%;
    padding: 10px;
    transition: 0.3s ease-in 0s;

    &:hover {
      color: #FFFFFF;
      background: #3F87F5;
    }
  }

  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}

.pagination-div {
  a.page-link {
    &.active {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #FFFFFF;
      background: #0652DD;
      border-radius: 4px;
    }

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #0652DD;
    background: #fff;
    border-radius: 4px;
    border-color: #0652DD;
  }

  nav ul {
    align-items: center;

    &.pagination {
      flex-wrap: wrap;
    }

    li.page-item {
      margin-right: 8px;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      font-family: Montserrat, sans-serif;

      .current-page {
        border: 1px solid #0652DD;
        color: white;
        background-color: #0652DD;
        border-radius: 4px;
        padding: 8px 12px;
        font-family: Montserrat, sans-serif;
      }

      &:last-child .page-link {
        border: none;
      }
    }
  }

  margin-top: 30px;
  margin-bottom: 30px;
}

.sidebar-social-links {
  width: 100%;

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #3B424D;
  }

  ul li {
    border: none;
    list-style: none;

    .fa {
      font-size: 2rem;
      color: #3F87F5;

      &:hover {
        color: #0652DD;
      }
    }
  }
}

.main-vist {
  .main-visit-link {
    display: flex;
    margin-bottom: 13px;
    margin-top: 15px;

    &:hover {
      .counter p {
        color: #3F87F5;
      }

      .link-post-name a {
        color: #3F87F5;
      }
    }

    .counter {
      margin-right: 17px;
      min-width: 42px;

      p {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        text-transform: uppercase;
        color: #9FC3FA;
        transition: 0.3s ease-in 0s;
      }
    }

    .link-post-name a {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #3B424D;
      transition: 0.3s ease-in 0s;
    }

    .link-name a {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #3B424D;
    }

    .user-name {
      margin-top: 5px;

      p {
        &.author-name {
          font-weight: 600;
          font-size: 11px;
          line-height: 18px;
          color: #3B424D;

          a {
            font-size: 11px;
          }
        }

        &.post-info-subtle {
          color: #a5acb7;
          font-size: 12px;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }

  margin-bottom: 70px;
}

/*======================================
//--//-->   Author Pages
======================================*/

.author-block {
  .blog-user-info {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;

    .user-image {
      margin-right: 24px;
    }

    .user-name h4 {
      font-weight: 600;
      font-size: 23px;
      line-height: 17px;
      color: #3B424D;
    }

    p.post-info-subtle {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #3B424D;
    }

    .user-name p {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #3B424D;
    }
  }

  padding: 21px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 20px;
}

.col-md-8.last-post {
  z-index: 300;
}

/*======================================
//--//-->   Post Pages
======================================*/

.transparent-menu:not(.blue-menu-provisory) {
  background: transparent;
}

.bannercontent-5 {
  span.cat {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: white;
    width: 149px;
    padding: 3px 12px;
    height: 20px;
    background: #428DFF;

    a {
      color: white;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 10px;
  }
}

p {
  &.post-time {
    font-style: italic;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
  }

  &.post-author {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
  }
}

.post-author span {
  text-decoration: underline;
}

.bannercontent-5 .post-anchor {
  p {
    color: #FFFFFF;

    a {
      color: #FFFFFF;
    }
  }

  margin-top: 20px;
}

.cta-block {
  margin-top: -80px;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  font-family: 'Montserrat', sans-serif;
}

.cta a.btnbnr {
  font-size: 14px;
  min-width: 229px;
  height: 50px;
  padding: 15px;
}

.cta-block {
  .row {
    align-items: center;
  }

  .txt p {
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
  }
}

.txt {
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 0;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.9);
  }
}

.post-data p {
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
  margin-bottom: 33px;
  font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 21px;
  color: #3b424d;
}

ul.data-bullet {
  li {
    font-family: Lora;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.84);
  }

  list-style: disc;
}

.tempo {
  text-align: center;
  border: 5px solid #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.tempo th {
  &:first-child {
    border-right: 1px solid #fff;
  }

  background: #5A6370;
  color: #fff;
}

.data-sheet p {
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  color: #99A1AD;
  margin-top: 20px;
}

.data-sheet-image {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 20px;
}

.comnt-btn {
  background: #F1F2FF;
  border-radius: 4px;
  padding: 21px;
}

a.btn-comment {
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #3B424D;

  span i.fa.fa-comment-o {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    font-size: 48px;
    margin: 10px 10px;
  }
}

section.artigos {
  background: #F6F8FA;
  padding-top: 50px;
}

.artigos-title h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #FFFFFF;
  background: #428DFF;
  width: 256px;
  padding: 5px;
  text-align: center;
  margin-bottom: 20px;
}

.artigos-post {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 10px 10px 0px 10px;
  margin-bottom: 20px;
}

.artigos .chat-section .chat-submit-form form {
  width: 100%;
}

.post-data-page {
  padding-top: 40px;
  letter-spacing: -0.003em;
  line-height: 32px;
  font-size: 21px;
}

@media screen and (max-width: 765px) {
  .txt {
    h3 {
      font-size: 14px;
      line-height: 1.4;
    }
  }

  .cta-block {
    padding: 20px;
  }

  .cta a.btnbnr {
    font-size: 12px;
    width: 50px !important;
    height: auto;
    padding: 10px;
  }
}

.current {
  background-color: green;
}

.fa-star {
  color: yellow;
}

.scream {
  color: red;
  text-shadow: 2px 2px yellow;
  font-weight: bold;
}

/*======================================
//--//-->  AC Modal Forms
======================================*/

.modal-dialog {
  margin-top: 5.75rem;
}

.modal-dialog .modal-content .modal-body {
  display: flex;
}



#someone-purchased {
  position: fixed;
  left: 27px;
  display: flex;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  transition: bottom 0.5s;
  transition: height 0.5s ease-in-out;
  border-radius: 10px;
  font-size: 18px;
  font-family: Arial, sans-serif;
  line-height: 1.5;
  z-index: 9999;
}

#someone-purchased * {
  border-radius: 10px;
}

#someone-purchased img {
  width: 90px;
  height: 90px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
}

p.fomo-notification-content {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 20px;
}

.fomo-notification-content-wrapper {
  display: inline;
  position: relative;
  padding: 10px 20px;
  width: 275px;
}

#close-popup {
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 11px;
  font-size: 15px;
}

#try-now {
  line-height: 12px;
  font-size: 12px
}

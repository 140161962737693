/*======================================
//--//-->   programa Pages
======================================*/

.features {
  margin-top: -250px;
}

.row.homegrid2 {
  justify-content: space-around;
}

.homegrid2 {
  .prodcol {
    padding: 60px 20px 45px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    background: #fff;
    min-height: 390px;
  }

  .content p {
    font-size: 18px;
    margin-top: 40px;
    line-height: 24px;
  }

  .col-md-4 .image img {
    height: 115px;
  }
}

section.container-fluid.mdlcontainer2 {
  background: #E9EEF5;
}

.cta-text-content {
  margin-top: 60px;
  /*margin-bottom: 313px;*/
  margin-bottom: 20vh;

  .cta-btn {
    margin-bottom: 20px;

    a.btnbnr {
      min-width: 476px;
      font-size: 16px;
      height: 50px;
      padding: 14px;
    }
  }

  .text-content {
    height: 51px;
    margin-top: 25px;

    p {
      background: linear-gradient(90deg, #ECEDFD 0%, #FFFFFF 50.58%, #ECEDFD 100%);
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #3B424D;
      padding: 20px 90px 13px;
      width: 700px;
      margin: 0 auto;
    }
  }
}

.feature {
  display: -webkit-inline-box;
  margin-bottom: 100px;
  min-height: 450px;
}

.note-details {
  padding: 42px 20px;
  background: #3F87F5;
  position: relative;
}

.note-title {
  max-width: 250px;
  background: #0652DD;
  flex: 0 0 100%;
  position: absolute;
  left: -110px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
  }
}

.note-description p {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 28px;
}

.programa-homegrid p {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #3B424D;

  span {
    font-size: 16px;
  }
}

.divider {
  margin-top: 90px;

  hr {
    max-width: 340px;
    border: 1px solid #3F87F5;
  }
}

.check-title h3 {
  font-size: 23px;
}

.gap {
  gap: 8px;
}

.check-box {
  background: #FFFFFF;
  width: 299px;
  display: inline-flex;
  border-radius: 4px;
  padding: 40px 31px;
  align-items: center;
}

i.fa.fa-check-circle {
  color: #85BD5A;
  font-size: 26px;
}

.checkbtn {
  margin-right: 19px;

  i {
    font-size: 26px;
  }
}

.check-heading p {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3B424D;
}

.check-box.active {
  position: relative;
  background: #85BD5A;

  &:before {
    content: "";
    border-radius: 100px;
    width: 80px;
    height: 80px;
    position: absolute;
    display: inline-block;
    right: 6px;
    bottom: -45px;
    box-shadow: -8px 1px 4px rgba(0, 0, 0, 0.25);
  }

  &:after {
    content: "";
    width: 80px;
    height: 80px;
    background: url(../images/vector.png) no-repeat;
    position: absolute;
    display: inline-block;
    right: 8px;
    bottom: -48px;
  }
}

.activebox {
  background: #85BD5A;
}

.check-box.active {
  .check-heading p {
    color: #ffffff;
  }

  .fa-check-circle:before {
    background: #fff;
    border-radius: 50%;
    border: solid 1px #fff;
  }
}

.slider-title p {
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #3B424D;
}

.home-foot-banner {
  padding-top: 100px;

  ul li {
    list-style: outside;
  }
}

.above-footer {
  .headingtxt {
    h4 {
      font-size: 27px;
      line-height: 32px;
    }

    h3 {
      font-size: 23px;
      line-height: 32px;
    }
  }

  width: 740px;
  margin-bottom: 100px;
  margin-top: 100px;
}

.full-info {
  background: #ECEDFD;
  border-radius: 4px;
  padding: 19px 32px;
  margin-bottom: 10px;

  h6 {
    span {
      font-style: italic;
      font-weight: 800;
      font-size: 39px;
      line-height: 48px;
      color: #3F87F5;
    }

    font-size: 16px;
    line-height: 23px;
    color: #3B424D;
  }
}

.above-footer .cta-btn {
  .btnbnr {
    min-width: 234px;
    font-size: 16px;
  }

  margin-top: 21px;
}

.defaultcarousel {
  width: 100%;
  text-align: center;
  padding: 25px 0 0;
}

.footrow .footcol {
  padding: 0px 35px;

  p,
  ul li {
    font-size: 14px;
    line-height: 22px;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-top: 25px;
  }

  h3 {
    font-size: 21px;
    font-weight: 700;
  }
}

.featuresgrid {
  padding-bottom: 35px;
}

.featureitem {
  .bluebox {
    background-image: linear-gradient(to bottom, #E9F2FF 30%, #FFFFFF);
    padding: 44px;
    min-height: 520px;
    width: 120%;
    overflow: hidden;
    position: relative;
  }

  .imgarea {
    width: 100%;
    margin-left: -20%;
    text-align: center;
    z-index: 1;
  }

  display: flex;
  flex-direction: row;
  padding-bottom: 35px;

  &:nth-child(odd) {
    .bluebox {
      order: 1;
    }

    .imgarea {
      order: 2;
    }
  }

  &:nth-child(even) {
    .imgarea {
      order: 1;
      margin-right: -20%;
      margin-left: 0;
    }

    .bluebox {
      order: 2;
      width: 120%;
    }
  }
}

.bluebox .boxcontentarea {
  width: 100%;
  padding: 50px 200px 25px 0;
}

.featureitem {
  &:nth-child(even) .boxcontentarea {
    padding: 50px 0 25px 200px;
    text-align: left;
  }

  &:first-child .bluebox:before {
    background: #e9eef5;
  }
}

.bluebox .boxcontentarea {
  h2 {
    font-size: 1.8rem;
    color: #0652DD;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 25px;
  }

  p {
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: #6c757d;
  }
}

.visual {
  .featureitem {
    .imgarea {
      order: 1;
      margin-right: -20%;
      margin-left: 0;
    }

    .bluebox {
      order: 2;
      width: 120%;
    }
  }

  .boxcontentarea {
    padding: 50px 0 25px 200px;
    text-align: left;
  }

  .featureitem .bluebox {
    &:before {
      top: -55px;
      left: -4px;
      position: absolute;
      content: "";
      height: 70px;
      width: 101%;
      display: inline-block;
      background: #EEEEEE;
      transform: rotate(-2.3deg);
      -moz-transform: rotate(-2.3deg);
      -ms-transform: rotate(-2.3deg);
      -webkit-transform: rotate(-2.3deg);
      -o-transform: rotate(-2.3deg);
    }

    &:after {
      bottom: -50px;
      left: -4px;
      position: absolute;
      content: "";
      height: 70px;
      width: 101%;
      display: inline-block;
      background: #EEEEEE;
      transform: rotate(2.3deg);
      -moz-transform: rotate(2.3deg);
      -ms-transform: rotate(2.3deg);
      -webkit-transform: rotate(2.3deg);
      -o-transform: rotate(2.3deg);
    }
  }
}

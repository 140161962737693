/*======================================
//--//-->   NAVBAR
======================================*/

.mainNav {
  .nav-item {
    &.btnlast {
      background: #3f87f5;
      border: 1px solid #3f87f5;
      border-radius: 6px;
      padding: 0px 8px;
      margin-left: 15px;

      &:hover {
        background: #275fb4;
      }

      a {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }

    a {
      color: #3B424D;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        color: #3f87f5;
      }
    }
  }

  .dropdown-header {
    padding-left: 0.5rem;
    font-size: 18px;
    font-weight: 500;
  }

  li {
    &.nav-item {
      font-weight: 600;
    }

    &.copyright {
      display: none;
    }
  }
}

/* --/Inner page/-- */

.blue-menu {
  background: #3F87F5;

  .logo-black {
    display: none;
  }

  .logo-default {
    display: none;
  }

  .logo-white {
    display: block;
  }
}


.logo-white {
  display: none;
}

.blue-menu {
  .nav-item {
    a {
      color: #fff;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        color: #fff;
        text-shadow: 0 0 3px rgba(255, 255, 255, 0.5), 0 0 5px rgba(255, 255, 255, 0.5);
      }
    }

    &.btnlast {
      background: #fff;
      border: 1px solid #fff;
      border-radius: 6px;
      padding: 0px 8px;
      margin-left: 15px;

      a {
        color: #1976D2;
      }
    }
  }
}

.blue-menu-provisory {
  @extend .blue-menu;
}

.navbar-desktop {
  display: none;
}

/*--/ Hamburger Navbar /--*/

.navbar-toggler {
  position: relative;

  &:focus,
  &:active {
    outline: 0;
  }

  span {
    display: block;
    background-color: #3B424D;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;

    &:nth-child(1),
    &:nth-child(3) {
      transition: -webkit-transform .35s ease-in-out;
      transition: transform .35s ease-in-out;
      transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
    }
  }

  &:not(.collapsed) span {
    &:nth-child(1) {
      position: absolute;
      left: 12px;
      top: 10px;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      opacity: 0.9;
    }

    &:nth-child(2) {
      height: 12px;
      visibility: hidden;
      background-color: transparent;
    }

    &:nth-child(3) {
      position: absolute;
      left: 12px;
      top: 10px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      opacity: 0.9;
    }
  }
}

/*======================================
//--//-->  Smooth Sticky Navbar
======================================*/

.fixed-top {
  width: 100%;
  z-index: 9000;
  transform: translateZ(0);
  transition: all 0.5s;

  /* Effect for switching from .fixed to static */
  a {
    font-size: 16px;
    text-decoration: none;
    color: #444;
    display: inline-block;
    padding: 10px 20px 10px 0px;

    .fa-gg {
      color: #F0595C;
      font-size: 30px;
      vertical-align: middle;
      transition: all 1s;
    }
  }
}

.fixed {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 4px 0;
  background: #fff;
  animation: slide-down 0.7s;

  a {
    color: #fff;
  }

  .fa-gg {
    transform: rotate(360deg);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}


/* --/inner pages /--*/

.blue-menu.fixed {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 4px 0;
  background: #3F87F5;
  animation: slide-down 0.7s;
  z-index: 999;
}

@media (min-width: 768px) {
  .blue-menu-provisory {
    @extend .blue-menu;
  }

  .navbar-desktop {
    .dropdown {
      display: inline-block;
      list-style: none;

      &:hover {
        .dropdown-menu {
          display: block;
          position: absolute;
          transform: translate3d(5px, 26px, 0px);
          top: 0px;
          left: 0px;
        }
      }
    }
  }

  .navbar-mobile {
    display: none;
  }

  .navbar-desktop {
    display: contents;
  }
}

/*======================================
//--//-->   Breadcrumb
======================================*/
.nav-breadcrumb {
  .breadcrumb {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
    border-radius: 0.25rem;
    background-color: transparent;
    width: auto;
    padding: 0.25rem 0.5rem;
    text-align: center;
    line-height: 1.4;
  }

  li {
    font-size: 12px;
    line-height: 1.4;
    vertical-align: middle;
    text-align: center;
  }

  svg {
    width: 15px;
    height: 15px;
    padding-bottom: 2px;
    margin: 0;
  }
}

.dark-background {
  color: #fff;

  .breadcrumb {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
  }

  .breadcrumb-item {
    color: #fff;

    &::before {
      color: #fff;
    }
  }

  li {
    color: #fff;

    a {
      color: #ffffff;
      background-color: transparent !important;
      height: auto;

      &:hover {
        color: #9dddfb;
        transition-duration: 150ms;
        background-color: transparent !important;
        border: none;
      }
    }
  }

  path {
    fill: #ffffff;

    &:hover {
      fill: #9dddfb;
      transition-duration: 150ms;
    }
  }
}

.light-background {
  .breadcrumb {
    background-color: rgba(217, 217, 217, 0.3);
  }

  li {
    a {
      color: #6c757d;
      background-color: transparent !important;
      height: auto;

      &:hover {
        color: #3F87F5;
        transition-duration: 150ms;
        background-color: transparent !important;
        border: none;
      }
    }
  }

  path {
    fill: #6c757d;

    &:hover {
      fill: #3F87F5;
      transition-duration: 150ms;
    }
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "❯";
}

.top-banner {
  height: 45px;
  padding: 5px 25px;
  background-color: #FBDD29;
  color: #010549;
  p {
    font-size: 16px;
    margin: 0px;
    font-weight: 600;
  }
}

div.roofline {
  background: #0652dd;
  position: sticky;
  top: 0;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 8px;
}

div.roofline>a {
  color: #fff;
  text-decoration: underline;
}

div.roofline>a:hover {
  font-size: 1.12em;
  transition: 0.25s;

}


@media (max-width: 765px) {
  nav.mainNav {
    margin-top: 1vh !important;
  }
}

@media (max-width: 480px) {
  nav.mainNav {
    margin-top: 0 !important;
  }
}

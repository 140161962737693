/*======================================
//--//-->   Videos Pages
======================================*/

.landing-page-section {
  margin-top: 40px;
  margin-bottom: 40px;

  p {
    line-height: 1.5em;
    margin-top: 1.1em;
    margin-bottom: 1.1em;
  }

  h1 {
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: bold;
  }
}

.tudo-margin {
  margin-bottom: 130px;
}

.mdlcontainer-4 {
  margin-top: -70px;
}

.videos {
  z-index: 300;
}

.videos-block {
  position: relative;
  overflow: hidden;
}

.videos-top-description p {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3B424D;
}

.videos-top-image {
  position: relative;
  img {
    width: 100%;
  }
}

.videos-top-description {
  padding-top: 21px;
}

.videos-content h3 {
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  text-transform: uppercase;
  color: #FFFFFF;
  position: relative;
  z-index: 1;
}

.videos-image {
  position: relative;
}

.video-overlay {
  align-self: center;
  font-size: 80px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;

  &:hover {
    color: red;
  }
}

.videos-image img {
  width: 100%;
  max-height: 430px;
}

.videos-content {
  &:before {
    content: "";
    height: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%);
  }

  padding: 20px 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.videos-description {
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #3B424D;
  }
  margin-top: 17px;
  margin-bottom: 30px;
}

.video-wrapper {
  min-height: 430px;
}

.aside-video {
  margin-top: 100px;
}

.post-content {
  position: relative;
  margin-bottom: 30px;
}


/*======================================
//--//-->   video post Pages
======================================*/

.aside-container {
  .aside-title h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #3B424D;
  }

  max-width: 350px;

  .freebie-items {
    .item-btns {
      margin: 0 0 10px 0;
      max-width: 320px;
    }

    width: 100%;
    margin-bottom: 52px;
  }

  .ytube-btns {
    a.ytube-btn {
      background: #FF0000;
      border-radius: 10px;
      display: inline-flex;
      padding: 15px 15px 10px;
      font-weight: 500;
      font-size: 17.47px;
      line-height: 20px;
      color: #FFFFFF;
      img {
        margin-right: 8px;
      }
    }
    margin-bottom: 46px;
  }

  .follow-us h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #3B424D;
  }

  .follow-us-facebook {
    position: absolute;
    z-index: 10;
  }

  hr {
    margin-top: 0rem;
    margin-bottom: 18px;
  }
}

.video-post-container .post-anchor {
  margin-top: -17px;
  margin-bottom: 5px;
}

.post-anchor p {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #5A6370;

  a {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #5A6370;
  }
}

.video-post-title {
  margin-bottom: 20px;

  h1 {
    font-weight: 800;
  }
}

.video-post-description {

  p,
  li {
    font-size: 12pt;
    line-height: 18pt;
    margin: 10pt 0;
    color: rgba(0, 0, 0, 0.84);

    a {
      text-decoration: underline;
    }
  }
}

.video-post-backbtn {
  a.back-btn {
    width: 210px;
    background: #ECEDFD;
    padding: 7px 0 8px 0;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #3B424D;
    display: inline-block;
  }
  p {
    display: inline-block;
  }
}

section.chat-section {
  background: #F6F8FA;
  padding-top: 50px;
  position: relative;
}

.chat-section {
  .chat-submit-area-title h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
  }
  .chat-submit-form {
    text-align: -webkit-center;
    form {
      width: 520px;
      margin: 20px 0 96px 0;
    }
  }
}

.chat-submit-form {
  p {
    font-size: 12px;
    line-height: 18px;
    color: #6F7475;
    text-align: left;
    margin-top: 15px;
  }
  .confirm-btn {
    margin-top: 15px;
    float: right;
  }
  input.form-control {
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: .625rem;
    color: #99A1AD;
    font-size: 14px;
    &::placeholder {
      color: #99A1AD;
      font-size: 14px;
    }
    &::-ms-input-placeholder {
      color: #99A1AD;
      font-size: 14px;
      color: #99A1AD;
      font-size: 14px;
    }
  }
}

.confirm-btn {
  background: #85BD5A;
  border-radius: 4px;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 123px;
  padding: 7px 0;
  display: inline-block;
  border: none;
  font-weight: bold;
}

a.confirm-btn:hover {
  background: #2f5c0c;
}

.comment-area {
  h3 {
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    color: #3B424D;
    margin-bottom: 30px;
  }
  padding-bottom: 100px;
}

.comments {
  margin-top: 25px;
  margin-bottom: 25px;
}

/*======================================
//--//-->   Banner Section
======================================*/

.topcontainer {
  width: 100%;
  max-width: 1600px;
  margin: 0px auto;
}

.bannerimg {
  width: 100%;
  position: relative;
  min-height: calc(100vw * 0.4);

  img {
    width: 100%;
  }
}

.bannerwrapper {
  padding-top: 100px;
  padding-bottom: 90px;
  z-index: 1;
}

.postimg {
  background-size: cover;
  position: absolute;
  height: 300px;
  width: 100%;
  z-index: 0;
}

@media only screen and (max-width: 900px) {
  .bannerwrapper {
    padding-top: 120px;
    padding-bottom: 0;
    margin: auto;

    .post-data-page {
      padding-top: 20px;
    }
  }

  .postimg {
    height: 400px;

    &::before {
      height: 400px;
    }
  }
}

.bannerarea {
  width: 100%;
  position: absolute;
  top: 35%;

  &.download {
    right: 0px;
    left: 0px;
  }
}

.bannercontent {
  padding-left: 140px;
  max-width: 600px;

  h1 {
    font-weight: 800;
    font-size: 32px;
    line-height: 1.3;
    color: #3B424D;
  }

  h3 {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.3;
    color: #3B424D;
    margin: 15px 0 30px;

    span {
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

a.btnbnr {
  min-width: 350px;
  display: inline-block;
  text-align: center;
  background: #fb5a16;
  border-radius: 23px;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 15px;
  color: #fff;

  &:hover {
    background: #712809;
  }
}

.mobbanr,
.mobfootbnr,
.mobpostbnr {
  display: none;
}

/*======================================
//--//-->   Download Pages
======================================*/

.dwnsection {
  float: right;
  padding-left: 0;
  max-width: 520px;
  padding-right: 2vw;

  h1 {
    font-weight: 800;
    font-size: 38px;
    line-height: 42px;
    text-transform: uppercase;
    color: #3B424D;
  }

  h3 {
    font-weight: 500;
    font-size: 21px;
    line-height: 1.3;
    color: #3B424D;
    margin: 15px 0 30px;
  }

  a.btnbnr {
    width: 100%;
    max-width: 475px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 54px;
    text-transform: initial;
    padding: 15px;
  }
}

.new-btn {
  position: relative;

  a {
    display: inline-block;
    text-align: center;
    background: #fb5a16;
    border-radius: 80px;
    font-weight: bold;
    font-size: 16px;
    padding: 12px 25px;
    color: #fff;

    &:hover {
      background: #712809;
    }
  }

  img {
    margin-right: 10px;
  }
}

.cta-text-content.cta-text-content2 {
  a.btnbnr {
    height: 60px;
    line-height: 60px;
    border-radius: 54px;
    padding: 0;
    text-transform: initial;
    span {
      padding: 20px;
      line-height: 3.6;
    }
  }
  margin-bottom: 0;
}

section.freebie {
  margin-top: 79px;
}

.freebie .freebie-content .freebie-title {
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #3B424D;
    margin-bottom: 0;
  }

  hr {
    margin: 2px auto 5px auto;
    border: 1px solid #E9E9E9;
    width: 702px;
  }
}

.freebie-items {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 23px;
  margin-bottom: 74px;

  .item-btns {
    a {
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #3B424D;
      }

      display: table-cell;
    }

    background: #E9E9E9;
    border-radius: 4px;
    padding: 12px;
    max-width: 340px;
    margin: 5px 11px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
  }
}

.homegrid3 .prodcol {
  padding: 60px 20px 45px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  background: #fff;
  min-height: inherit;
  align-items: center;
}

.login-body {
  background: #3F87F5;
  .container-fluid {
    padding: 8px 0;
  }
}

.logo-image {
  text-align: center;
  padding: 30px 0 24px;
}

.login-form {
  margin: 0 auto 40px;
  max-width: 420px;
  text-align: center;
  padding: 40px 54px;
  background: #fff;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}

a {
  &.fb-btn {
    background: #405DAA;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    width: 312px;
    display: inline-block;
    padding: 15px 0;
    text-align: center;

    &:hover {
      background: #0c2568;
    }
  }

  &.ggl-btn {
    background: #F3553C;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    width: 312px;
    display: inline-block;
    padding: 15px 0;
    text-align: center;

    &:hover {
      background: #712809;
    }
  }
}

form i.fa.fa {
  font-size: 22px;
}

span.separator {
  display: flex;
  margin-top: 28px;
  margin-bottom: 21px;

  hr {
    width: 42px;
    margin: 10px;
    border: 1px solid #CECECE;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #3B424D;
  }
}


.login-form input.form-control {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
  color: #878B94;
  height: 50px;
  border: 1px solid #DCDDDF;
  box-sizing: border-box;
  border-radius: 4px;
}
a {
  &.entr-btn {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    background: #1976D2;
    border-radius: 44px;
    display: inline-block;
    padding: 12px 0;
    width: 106px;
    &:hover {
      background: #5596d5;
    }
  }
  &.forgot-in {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #99A1AD;
  }
}
.login-form .register {
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #3B424D;
  }
  a {
    text-decoration: underline;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #3B424D;
    &:hover {
      color: #1976d2;
    }
  }
}

nav hr {
  border: 1px solid rgba(255, 255, 255, 0.15);
  width: 100%;
  margin: 0px 0 40px;
}

.forgot {
  margin-top: 13px;
}

.register {
  margin-top: 7px;
}

/*======================================
//--//-->   Grid Section
======================================*/

.homegrid {
  padding-bottom: 35px;
}

.newitems {
  display: flex;
  flex-direction: row;

  &:nth-child(odd) .imgcontent {
    order: 1;
  }

  &:nth-child(even) .imgcontent {
    order: 2;
    text-align: center;
  }

  &:nth-child(odd) .gridcontent {
    order: 2;
  }

  &:nth-child(even) .gridcontent {
    order: 1;
    text-align: right;
  }
}

/*======================================
//--//-->   Inner Pages
======================================*/

.row.bannerarea {
  justify-content: space-between;
}

.bannercontent.bannercontent2 {
  float: right;
  h1 span {
    font-size: 50px;
    line-height: 1;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  a.btnbnr {
    font-weight: bold;
    font-size: 16px;
    min-width: 225px !important;
    height: 50px;
    padding: 14px;
  }
}

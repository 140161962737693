/*======================================
//--//-->   Calculators Pages
======================================*/
#content-widget {
  p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 8px 0 16px 0;
  }
}

#banner-widget {
  background-color: #0652DD;
  padding: 56px 0 48px 0;
  margin-top: 71px;

  h1 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    font-size: 2.45em;
    line-height: 1.1;
    padding: 0;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0;
  }

  .with-icon {
    display: block;
    position: relative;
    bottom: -73px;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-size: 30px 30px;
    z-index: 1;
    text-align: center;

    img {
      margin-top: 10px;
    }
  }
}

.bg-blue {
  background-color: #397ADB;
}

.bg-green {
  background-color: #239E54;
}

.bg-red {
  background-color: #DB5F4F;
}

.bg-gold {
  background-color: #B5821D;
}

.bg-purple {
  background-color: #351FC2;
}

#generator-widget {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  padding: 52px 0 52px 0;
}

#calculator-widget {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  padding: 52px 0 52px 0;

  h2 {
    padding: 8px 0 4px 0;
    font-weight: bold;
    font-size: 2rem;
    color: #0652DD;
  }

  h3 {
    padding: 8px 0 4px 0;
    font-weight: bold;
    font-size: 1.5rem;
    color: #0652DD;
  }

  h5, .card-subtitle {
    padding: 16px 0 4px 0;
    font-weight: normal;
    font-size: 1.3rem;
    color: #3b424d;
  }

  .card {
    margin-top: 48px;
    width: auto;

    .card-body {
      padding: 16px;
    }
  }

  table {
    thead {
      th {
        vertical-align: top;
      }
    }
  }
}

#content-widget table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
  margin-bottom: 36px;

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    font-weight: bold;

    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;

      th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
        border-top: 1px solid #dee2e6;
        padding: 0.75rem;
      }
    }
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;

    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;

      &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
      }

      th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        text-align: inherit;
        font-weight: bold;
        display: table-cell;
      }

      td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        display: table-cell;
      }
    }
  }
}

#info-widget {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;

  .mw-100 {
    max-width: 100%;
  }

  h2 {
    font-weight: bold;
    color: #0652DD;
    margin-top: 64px;
    margin-bottom: 30px;
    font-size: 2rem;

    &:first-child {
      margin-top: 0px;
    }
  }

  h3 {
    margin-bottom: 30px;
    margin-top: 64px;
    font-size: 1.6rem;

    &:first-child {
      margin-top: 0px;
    }
  }

  p {
    margin-bottom: 24px;
    line-height: 1.5;
  }

  a {
    color: #0652DD;
    text-decoration: underline;

    &:hover {
      color: #3F87F5;
    }
  }

  img {
    max-width: 600px;
  }

  pre {
  }

  blockquote {
    background-color: rgba(0, 0, 0, .03);
    border: 1px solid rgba(0, 0, 0, .125);
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    border-radius: 8px;
    margin-bottom: 36px;
  }

  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    margin-bottom: 36px;

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
      font-weight: bold;

      tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;

        th {
          vertical-align: bottom;
          border-bottom: 2px solid #dee2e6;
          border-top: 1px solid #dee2e6;
          padding: 0.75rem;
        }
      }
    }

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;

      tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;

        &:nth-of-type(odd) {
          background-color: rgba(0, 0, 0, .05);
        }

        th {
          padding: 0.75rem;
          vertical-align: top;
          border-top: 1px solid #dee2e6;
          text-align: inherit;
          font-weight: bold;
          display: table-cell;
        }

        td {
          padding: 0.75rem;
          vertical-align: top;
          border-top: 1px solid #dee2e6;
          border-bottom: 1px solid #dee2e6;
          display: table-cell;
        }
      }
    }
  }
}

#see-more-widget {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  margin-bottom: 36px;

  p {
    margin-bottom: 24px;
    line-height: 1.5;
  }
}

#form-widget {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  margin-bottom: 100px;
}

#about-widget {
  background-color: #0652DD;

  .about-content {
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 0 100px 0;
  }

  p {
    margin-bottom: 36px;
    line-height: 1.5;
  }

  a {
    font-weight: bold;
    color: #FFFFFF;
    text-decoration: underline;

    &:hover {
      color: #f0f0f0;
    }
  }
}

.btn-deep-blue {
  background-color: #0652DD;
  color: #FFFFFF;

  &:hover {
    background-color: #3F87F5;
    color: #FFFFFF;
  }
}

.btn-cj-blue {
  background-color: #3F87F5;
  color: #FFFFFF;

  &:hover {
    background-color: #4f92f7;
    border: solid 1px #FFFFFF;
    color: #FFFFFF;
  }
}

.btn-cj-yellow {
  background-color: #FFC106;
  color: #010549;

  &:hover {
    background-color: #f5cb3f;
    border: solid 1px #FFFFFF;
    color: #010549;
  }
}

.bg-deep-blue {
  background-color: #0652DD;
}

.bg-light-blue {
  background-color: #F2FAFF;
}

.text-deep-blue {
  color: #0652DD;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-img-radius-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@media (max-width: 768px) {
  #calculator-widget {
    .card {
      overflow-x: scroll;
    }
  }
}

@media (max-width: 480px) {
  #info-widget{
    img {
      max-width: 100%;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

.use-landing-page-v2 {

  /* --- NOT Tailwind like classes --- */
  .bg-email {
    background: #EE4444;
  }

  .bg-facebook {
    background: #3B5998;
  }

  .bg-twitter {
    background: #76A9EA;
  }

  .bg-linkedin {
    background: #006699;
  }

  .bg-whatsapp {
    background: #25D366;
  }

  .blue-span span {
    color: #007BFF;
  }

  .yellow-span span {
    color: #FFDE7E;
  }

  .bold-span span {
    font-weight: 700;
  }

  .hover\:img-blue-600:hover img {
    filter: invert(.6) sepia(1) saturate(5) hue-rotate(183deg);
  }

  /* This CSS is using the Tailwind pattern to define classes
    take a look at https://tailwindcss.com/ if you want to learn more
*/
  /* --- Tailwind like classes --- */

  body {
    font-family: 'Montserrat', sans-serif;
  }

  .outline-none {
    outline: none;
  }

  .border-none {
    border-style: none;
  }

  .fill-current {
    fill: currentColor;
  }

  .sticky {
    position: sticky;
  }

  .overflow-y-auto {
    overflow-y: auto;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .inline-block {
    display: inline-block;
  }

  .hidden {
    display: none;
  }

  .grid {
    display: grid;
  }

  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .gap-4 {
    gap: 1rem;
  }

  .gap-5 {
    gap: 1.25rem;
  }

  .items-center {
    align-items: center;
  }

  .items-start {
    align-items: start;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1 1 0%;
  }

  .flex-col {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  .rounded-full {
    border-radius: 9999px;
  }

  .rounded-sm {
    border-radius: 0.125rem;
  }

  .rounded {
    border-radius: 0.25rem;
  }

  .rounded-md {
    border-radius: 0.375rem;
  }

  .border-t-0 {
    border-top-width: 0px;
  }

  .border-b-2 {
    border-width: 0 0px 2px 0;
  }
  .border-b-4 {
    border-width: 0 0px 4px 0;
  }

  .border-b-8 {
    border-width: 0 0px 8px 0;
  }

  .border-solid {
    border-style: solid;
  }

  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .font-thin {
    font-weight: 100;
  }

  .font-extralight {
    font-weight: 200;
  }

  .font-light {
    font-weight: 300;
  }

  .font-normal {
    font-weight: 400;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-semibold {
    font-weight: 600;
  }

  .font-bold {
    font-weight: 700;
  }

  .font-extrabold {
    font-weight: 800;
  }

  .font-black {
    font-weight: 900;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .bottom-0 {
    bottom: 0;
  }

  .top-0 {
    top: 0;
  }

  .w-auto {
    width: auto;
  }

  .w-2\/3 {
    width: 66.666667%;
  }

  .w-4 {
    width: 1rem;
  }

  .w-5 {
    width: 1.25rem;
  }

  .w-6 {
    width: 1.5rem;
  }

  .w-8 {
    width: 2rem;
  }

  .w-10 {
    width: 2.5rem;
  }

  .w-11 {
    width: 2.75rem;
  }

  .w-12 {
    width: 3rem;
  }

  .h-2\/3 {
    height: 66.666667%;
  }

  .h-4 {
    height: 1rem;
  }

  .h-5 {
    height: 1.25rem;
  }

  .h-6 {
    height: 1.5rem;
  }

  .h-8 {
    height: 2rem;
  }

  .h-10 {
    height: 2.5rem;
  }

  .h-11 {
    height: 2.75rem;
  }

  .h-12 {
    height: 3rem;
  }

  .h-20 {
    height: 5rem;
  }

  .max-w-xl {
    max-width: 36rem;
  }

  .max-w-2xl {
    max-width: 42rem;
  }

  .max-w-3xl {
    max-width: 48rem;
  }

  .max-w-4xl {
    max-width: 56rem;
  }

  .max-w-5xl {
    max-width: 64rem;
  }

  .max-w-6xl {
    max-width: 72rem;
  }

  .max-h-96 {
    max-height: 24rem;
  }

  .mx-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .m-0 {
    margin: 0;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .mr-1\.5 {
    margin-right: 0.375rem;
  }

  .mr-2 {
    margin-right: 0.5rem;
  }

  .mr-2\.5 {
    margin-right: 0.625rem;
  }

  .mr-3 {
    margin-right: 0.75rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .mt-12 {
    margin-top: 3rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .mb-3 {
    margin-bottom: 0.75rem;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .mb-12 {
    margin-bottom: 3rem;
  }

  .mb-16 {
    margin-bottom: 4rem;
  }

  .my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .pr-7 {
    padding-right: 1.75rem;
  }

  .pr-8 {
    padding: 2rem;
  }

  .px-2 {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .px-3 {
    padding-right: .75rem;
    padding-left: .75rem;
  }

  .px-4 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .px-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .px-10 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .px-12 {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .px-14 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .p-0 {
    padding: 0px;
  }

  .p-0\.5 {
    padding: 0.125rem;
  }

  .p-1 {
    padding: 0.25rem;
  }

  .p-1\.5 {
    padding: 0.375rem;
  }

  .p-2 {
    padding: 0.5rem;
  }

  .p-2\.5 {
    padding: 0.625rem;
  }

  .p-3 {
    padding: 0.75rem;
  }

  .p-3\.5 {
    padding: 0.875rem;
  }

  .p-4 {
    padding: 1rem;
  }

  .p-5 {
    padding: 1.25rem;
  }

  .p-6 {
    padding: 1.5rem;
  }

  .p-7 {
    padding: 1.75rem;
  }

  .p-8 {
    padding: 2rem;
  }

  .p-9 {
    padding: 2.25rem;
  }

  .p-10 {
    padding: 2.5rem;
  }

  .p-11 {
    padding: 2.75rem;
  }

  .p-12 {
    padding: 3rem;
  }

  .p-14 {
    padding: 3.5rem;
  }

  .p-16 {
    padding: 4rem;
  }

  .p-20 {
    padding: 5rem;
  }

  .p-24 {
    padding: 6rem;
  }

  .p-28 {
    padding: 7rem;
  }

  .p-32 {
    padding: 8rem;
  }

  .p-36 {
    padding: 9rem;
  }

  .p-40 {
    padding: 10rem;
  }

  .p-44 {
    padding: 11rem;
  }

  .p-48 {
    padding: 12rem;
  }

  .p-52 {
    padding: 13rem;
  }

  .p-56 {
    padding: 14rem;
  }

  .p-60 {
    padding: 15rem;
  }

  .p-64 {
    padding: 16rem;
  }

  .p-72 {
    padding: 18rem;
  }

  .p-80 {
    padding: 20rem;
  }

  .p-96 {
    padding: 24rem;
  }

  .bg-blue-600 {
    background: #007BFF;
  }

  .bg-gray-200 {
    background: #F2F2F2;
  }

  .bg-gray-400 {
    background: #c2c4c6;
  }

  .bg-white {
    background: white;
  }

  .text-white {
    color: white;
  }

  .text-black {
    color: black;
  }

  .text-yellow-200 {
    color: #FFDE7E;
  }

  .text-blue-600 {
    color: #007BFF;
  }

  .text-gray-700 {
    color: #3B424D;
  }

  .border-blue-600 {
    border-color: #007BFF !important;
  }

  .border-white {
    border-color: white !important;
  }

  .focus\:bg-blue-600:focus {
    background-color: #007BFF !important;
  }

  .focus\:ring-blue:focus {
    box-shadow: 0 0 0 1px #3f87f5;
  }

  .hover\:bg-white:hover {
    background-color: white;
  }

  .hover\:bg-blue-50:hover {
    background-color: #eff6ff !important;
  }

  .hover\:bg-blue-100:hover {
    background-color: #b3d7ff !important;
  }

  .hover\:bg-blue-600:hover {
    background-color: #007BFF !important;
  }

  .hover\:bg-gray-200:hover {
    background-color: #F2F2F2 !important;
  }

  .hover\:opacity-60:hover {
    opacity: .6;
  }

  .hover\:text-blue-600:hover {
    color: #007BFF !important;
  }

  .hover\:text-white:hover {
    color: white !important;
  }

  .duration-75 {
    transition-duration: 75ms;
  }

  .duration-200 {
    transition-duration: 200ms;
  }

  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  /* sm - Small screens */
  @media (min-width: 640px) {
    .container {
      max-width: 640px;
    }
  }

  /* md - Medium screens */
  @media (min-width: 768px) {
    .container {
      max-width: 768px;
    }

    .md\:max-w-6xl {
      max-width: 72rem;
    }

    .md\:flex {
      display: flex;
    }

    .md\:hidden {
      display: none;
    }

    .md\:flex-col {
      flex-direction: column;
    }

    .md\:flex-row {
      flex-direction: row;
    }

    .md\:text-center {
      text-align: center;
    }

    .md\:text-left {
      text-align: left !important;
    }

    .md\:justify-start {
      justify-content: flex-start;
    }

    .md\:items-stretch {
      align-items: stretch;
    }

    .md\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .md\:mb-0 {
      margin-bottom: 0px;
    }

    .md\:mt-0 {
      margin-top: 0px;
    }

    .md\:p-0 {
      padding: 0px;
    }

    .md\:px-0 {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    .md\:px-12 {
      padding-right: 3rem;
      padding-left: 3rem;
    }

    .md\:pr-0 {
      padding-right: 0px;
    }

    .md\:pr-8 {
      padding-right: 2rem;
    }

    .md\:h-full {
      height: 100%;
    }

    .md\:h-auto {
      height: auto;
    }

    .md\:h-36 {
      height: 9rem;
    }
  }

  /* lg - Large screens */
  @media (min-width: 1024px) {
    .container {
      max-width: 1024px;
    }

    .lg\:max-w-4xl {
      max-width: 56rem;
    }

    .lg\:max-w-5xl {
      max-width: 64rem;
    }

    .lg\:max-w-6xl {
      max-width: 72rem;
    }

    .lg\:overflow-y-visible {
      overflow-y: visible;
    }
  }

  /* xl - Extra Large screens */
  @media (min-width: 1280px) {
    .container {
      max-width: 1280px;
    }

    .xl\:max-w-4xl {
      max-width: 56rem;
    }

    .xl\:max-w-6xl {
      max-width: 72rem;
    }
  }

  /* 2xl - 2x Extra Large screens */
  @media (min-width: 1536px) {
    .container {
      max-width: 1536px;
    }
  }
}

/*======================================
//--//-->   Mdl Container
======================================*/

.mdlbg {
  background: url(../images/leftshape.png) no-repeat;
  min-height: 600px;
}

.mdlcontainer {
  padding: 100px 0 50px;
}

.youtube-cta {
  margin-top: 40px;
  margin-bottom: 40px;
}

.headingtxt {
  text-align: center;
  padding-bottom: 40px;
  h4 {
    background: #3f87f5;
    display: inline-block;
    font-size: 20px;
    color: #fff;
    font-weight: 800;
    padding: 5px;
  }
  h3 {
    font-size: 32px;
    margin-top: 8px;
    span {
      font-weight: 800;
    }
  }
}

.gridcontent {
  h2 {
    font-size: 26px;
    font-weight: 800;
    color: #3B424D;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #3B424D;
    line-height: 1.5;
  }
}

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  width: 100%;
  min-height: 100%;
}

body {
  color: #3B424D;
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3B424D;
}

img {
  max-width: 100%;
}

.card-body {
  p {
    margin-bottom: 10px;
  }
}

p {
  margin: 0;
  padding: 0;
  line-height: 25px;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

ul {
  &.no-bull {
    list-style: none;
  }

  &.no-padding {
    padding-inline-start: 16px;
  }
}

.no-pad {
  padding: 0 !important;
}

.no-spacing {
  margin: 0 !important;
}

.no-pad-rt {
  padding-right: 0 !important;
}

.no-pad-lft {
  padding-left: 0 !important;
}

.no-floting {
  float: none;
}

.hor-center {
  margin: auto;
}

.hor-spacer-10 {
  margin-top: 10px;
}

.rel {
  position: relative;
}

.fa-info-circle {
  color: lightblue;
  cursor: help;
}

.tooltip-inner {
  min-width: 350px;
}

/* ---------------- For Animation on Scroll ---------------- */

.slideanim {
  visibility: hidden;
  visibility: visible\9;
  /*For old IE browsers IE6-8 */
}

.slideanim.slide {
  visibility: visible;
  animation: slide 1s;
}

.slideanim::after {
  /* useful when its child elements are float:left; */
  content: "";
  display: table;
  clear: both;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ---------------- Transition ---------------- */

.fade-in {
  animation: fade-in 0.4s ease-out;
}

.fade-out {
  animation: fade-out 0.5s ease-in;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slide-fade-little-up {
  animation: slide-fade-little-up 0.2s ease-in;
}

.slide-fade-little-down {
  animation: slide-fade-little-down 0.3s ease-out;
}

@keyframes slide-fade-little-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  16% {
    opacity: 0;
    transform: translateY(-8%);
  }

  100% {
    opacity: 0;
    transform: translateY(-50%);
  }
}

@keyframes slide-fade-little-down {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }

  16% {
    opacity: 0;
    transform: translateY(-42%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-fade-complete-up {
  animation: slide-fade-complete-up 0.2s ease-in;
}

.slide-fade-complete-down {
  animation: slide-fade-complete-down 0.3s ease-out;
}

@keyframes slide-fade-complete-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-50%);
  }
}

@keyframes slide-fade-complete-down {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

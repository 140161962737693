/*======================================
//--//-->   Plan Pages
======================================*/

.feature-box:hover .feature-image {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: 2;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.blue-gradient {
  background: #3F87F5;
}

.text-plan-price {
  text-decoration: underline;
}

.blue-banner p {
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  padding-top: 16px;
  margin-bottom: 24px;
  color: #FFFFFF;

  strong {
    background: #FBDD29;
    border-radius: 4px;
    color: #010549;
    padding: 6px;
  }
}

.blue-banner-plan {
  h1 {
    font-weight: 800;
    font-size: 3rem;
    margin-bottom: 0;
    line-height: 1.2;
    color: #FFFFFF;
  }
}

.plan-check-yes {
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    left: 50%;
    top: -0.6rem;
    margin: 0 0 0 -1rem;
    border-radius: 100%;
    background: #0652DD url(/assets/images/check-yes-plan.svg) center/50% no-repeat;
  }
}

.plan-card-text {
  font-size: 85%;
  line-height: 1.6;
}

.text-smaller {
  font-size: 75%;
}

.btn-yellow-light {
  background-color: #ffc107;
  color: #010549 !important;
  border-color: #ffc107;

  &:hover {
    background-color: #e0a800;
    color: #010549 !important;
    border-color: #d39e00;
  }
}

.btn-yellow-border {
  background-color: #FBDD29;
  color: #010549;

  &:hover {
    background-color: #FFFFFF;
    color: #010549;
    border: solid 1px #FBDD29;
  }
}

.btn-outline-yellow {
  color: #010549 !important;
  border: solid 1px #d39e00 !important;

  &:hover {
    background-color: #e0a800 !important;
    border-color: #d39e00 !important;
    color: #010549 !important;
  }
}

.btn-green {
  background-color: green;
  color: #FFFFFF;

  &:hover {
    background-color: #90EE90;
    color: #FFFFFF;
  }
}

.btn-custom-cta {
  background-color: orange;
  color: #FFFFFF;

  &:hover {
    background-color: darkorange;
    color: #FFFFFF;
  }
}

.btn-blue {
  background-color: #0652DD;
  color: #FFFFFF;

  &:hover {
    background-color: #3F87F5;
    color: #FFFFFF;
  }
}

.text-blue {
  color: #3F87F5;
}

.background-blue-banner {
  background-color: #3F87F5;
}

.text-dark-blue {
  color: #010549;
}

.block-pricing {
  text-align: center;
}

.block {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
}

.block-pricing .table {
  padding: 15px !important;
  margin-bottom: 0px;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table-word-break {
  td {
    word-wrap: break-word;
    word-break: break-word;
  }
}

.block .category:not([class*="text-"]) {
  color: #3C4857;
}

.block-pricing .block-caption {
  margin-top: 30px;
}

.block-caption {
  font-weight: 700;
  font-family: "Lato", "Times New Roman", serif;
  color: #3C4857;
}

p.block-description {
  color: #3C4857;
}

[class*="pricing-"] {
  padding: 90px 0 60px 0;
}

.plan-features {
  background-color: #EEEEEE;
  padding-top: 100px;
  padding-bottom: 353px;

  .headingtxt h3 {
    font-size: 2.1rem;
    line-height: 1.3;
  }
}

.calc-price-calculator {
  h3 {
    font-size: 2.1rem;
    line-height: 1.3;
    color: #010549;
  }
}

.feat-txt {
  h4 {
    font-weight: bold;
    font-size: 15px;
    line-height: 23px;
    color: #010549;
  }

  p {
    font-size: 13px;
    line-height: 20px;
    color: #212529;
  }
}

.feature-image img {
  height: 52px;
  max-width: inherit;
}

.feature-box {
  margin-bottom: 80px;
}

.visual {
  margin-top: -230px;
}

.partners h3 {
  font-weight: 500;
  font-size: 21px;
  line-height: 20px;
  text-align: center;
  color: #383F5C;
}

.partners-logo {
  margin: 70px 0;
  align-items: center;
}

.support-grade-new {
  background-color: #ffffff;
  padding: 15px;
  z-index: 1;
  width: 200px;
  text-align: center;
  align-self: center;
  position: absolute;
  left: 20px;
  font-size: calc(.7rem + .5vh);
  line-height: 1.4;
}

.faq-title {
  font-size: 1.15rem;
  font-weight: 500;
  line-height: 1.4;

  &:hover {
    color: #0652DD;
  }
}

.togglePlus {
  width: 16px;
  height: 16px;
  fill: inherit;
  flex-shrink: 0;
  transform: rotate(45deg);
  transition: transform 100ms ease-in-out;
}

.collapsed {
  .togglePlus {
    transform: rotate(0deg);
    transition: transform 100ms ease-in-out;
  }
}

.faq {
  h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 32px;
  }

  .card {
    background: #E9EEF5;
    border: none;
  }
}

.background-gray {
  background-color: #EEEEEE;
}

.card-header {
  background: #E9EEF5;
  border: none;
}

.faq {
  .card-header {
    padding: 0rem 0.25rem;
  }

  .card {
    margin-bottom: 5px;
  }

  .card-body {
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #383F5C;
  }

  .card-header h5 a i {
    float: right;
    background: #fff;
    border-radius: 50%;
    padding: 6px 7px;
    color: rgba(56, 63, 92, 0.4);
  }
}

.faq-cta {
  margin-top: 70px;
  margin-bottom: 100px;

  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #383F5C;
  }

  .btnbnr {
    margin-top: 20px;
    min-width: 235px;
    background: #1976D2;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 14px;

    &:hover {
      background: #3193cb;
    }
  }
}

.testimony {
  padding: 100px 0;
  background: #E9EEF5;

  .headingtxt {
    h3 {
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      color: #383F5C;
    }

    padding-bottom: 30px;

    &.headingtxtbtm h3 {
      margin-top: 72px;
      font-size: 22px;
      line-height: 35px;
      text-align: center;
      color: #383F5C;
    }
  }
}

.testimony-video {
  padding: 50px 0;
  background: #010549;
  color: #c2c4c6;

  .headingtxt {
    h4 {
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      color: #ffffff;
    }
  }
}

/*again*/

/*======================================
//--//-->   Plan Pages
======================================*/
.bg-dark-blue {
  background-color: #010549;
}

.bg-dark-gray {
  background-color: #0a0a0a;
}

.bg-deep-blue {
  background-color: #231951;
}

.bg-cj-blue {
  background-color: #3F87F5;
}

.bg-blue {
  background-color: #3DB3F2;
}

.bg-light-blue {
  background-color: #EAF7FE;
}

.bg-orange {
  background-color: #F4520D;
}

.text-orange {
  color: #F4520D;
}

.bg-yellow {
  background-color: #EEC345;
}

.bg-gray {
  background-color: #F5F6F8;
}

.text-dark-blue {
  color: #363F5E;
}

.text-gray {
  color: #3B424D;
}

.text-light-gray {
  color: #999999;
}

.text-deep-blue {
  color: #0652DD !important;
}

.text-cj-blue {
  color: #3F87F5;
}

.text-blue {
  color: #3DB3F2;
}

.text-light-blue {
  color: #00A6FF;
}

.badge-light-blue {
  background-color: #D4F0FC;
}

.text-yellow {
  color: #EEC345;
}

.text-green {
  color: #2da44e;
}

.text-red {
  color: #cf222e;
}

.text-32 {
  font-size: 32px;
  line-height: 40px;
}

.text-30 {
  font-size: 1.875em;
  line-height: 1.2;
}

.text-24 {
  font-size: 1.5rem;
  line-height: 1.4;
}

.text-22 {
  font-size: 1.375rem !important;
  line-height: 1.4;
}

.text-20 {
  font-size: 20px !important;
  line-height: 1.4;
}

.text-18 {
  font-size: 1.125rem !important;
  line-height: 1.3;
  font-weight: 500;
}

.text-16 {
  font-size: 1em !important;
  line-height: 1.3 !important;
  font-weight: 400;
}

.text-14 {
  font-size: 0.875em !important;
  line-height: 1.3;
  font-weight: 500;
}

.text-13 {
  font-size: 0.813em !important;
  line-height: 1.6;
}

.text-12 {
  font-size: 0.75em;
  line-height: 1.2;
  font-weight: 500;
}

.text-11 {
  font-size: 11px;
  line-height: 1.3;
}

.text-10 {
  font-size: 10px;
  line-height: 1.3;
}

.text-9 {
  font-size: 9px;
  line-height: 1.2;
}

.text-8 {
  font-size: 8px;
  line-height: 1.2;
}


.btn-light-blue {
  background-color: #00a6ff !important;
  font-weight: bolder;
  color: #ffffff;
}

.btn-light-blue:hover {
  background-color: #056fa8 !important;
  font-weight: bolder;
  color: #ffffff;
}

.btn-deep-blue {
  background-color: #0652DD;
}

.btn-deep-blue:hover {
  background-color: #3F87F5;
}

.btn-green {
  background-color: #85BD5A;
}

.btn-green:hover {
  background-color: #699c42;
}

.icon-hover {
  opacity: 100%;

  &:hover {
    opacity: 70%;
  }
}

.cards-features {
  background-color: #eff9ff !important;
  border: solid 2px #3F87F5 !important;
  box-shadow: 1px 2px 4px #F5F6F8;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.faq-item {
  color: #363F5E !important;
  text-decoration: none !important;
  font-size: 1em !important;
  line-height: 1.4 !important;
  font-weight: 500 !important;
}

.faq-item:hover {
  color: #0652DD !important;
  text-decoration: none !important;
}

.card {
  .collapsed {
    background-color: #F5F6F8;
    border-radius: 0 !important;
  }
}

.togglePlus {
  width: 16px;
  height: 16px;
  fill: inherit;
  flex-shrink: 0;
  transform: rotate(45deg);
  transition: transform 100ms ease-in-out;
}

.collapsed .togglePlus {
  transform: rotate(0deg);
  transition: transform 100ms ease-in-out;
}

#banner-plans {
  margin-top: 50px;
  margin-bottom: 25px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: right;
  background-color: #FFFFFF;
}

.calculator-app {
  border-radius: 8px;
  background-color: #EAF7FE;
}

.border-radius {
  border-radius: 8px;
}

.bg-circle {
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  background-color: #D4F0FC;
}

.bg-muted {
  background-color: #d6ecfa;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #d6ecfa;
  margin-left: 35px;
  margin-right: auto;
}

.client {
  border-radius: 20px;
}

.cards-reasons {
  border-left: solid 2px #dddddd !important;
}

.cards-reasons:hover {
  border-left: solid 2px #0652DD !important;
  transition: .75s;
}

.fill-deep-blue {
  fill: #0652DD;
}

.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.w-33 {
  width: 33.33%;
}

.line-60 {
  line-height: 1.6;
}

.line-10 {
  line-height: 1.1;
}

.m-70 {
  margin-top: 71px;
}

.m-100 {
  margin-top: 100px;
}

.padding-top-2 {
  padding-top: 2px;
}

.padding-top-3 {
  padding-top: 3px;
}

.line-40 {
  line-height: 140%;
}

.search-xtend {
  padding-bottom: 345px !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.text-decoration {
  text-decoration: underline !important;
}

.border-deep-blue {
  border: 1px solid #0652DD;
}

.whatsapp {
  background-color: mediumseagreen;
  color: #ffffff;
  position: fixed;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 28px;
  z-index: 1;
  bottom: 80px;
  margin-right: 6px;

  &:hover {
    background-color: rgb(39, 142, 85);
    color: #FFFFFF;
  }
}

.table-nav-plans {
  background-color: #FFFFFF;

  .nav-plans {
    .nav-tabs {
      border-bottom: 1px solid #0652DD;

      .nav-item {
        .nav-link {
          font-size: 18px;
          border: 1px solid #dadada;
          border-bottom: 1px solid #0652DD;
          color: #010549;
          font-weight: 600;
          background-color: #FFFFFF;

          &:hover {
            background-color: #0652DD;
            border-bottom: 1px solid #0652DD;
            transition: ease-in .3s;
            color: #FFFFFF;
          }
        }

        .nav-link.active {
          background-color: #F5F9FE;
          color: #010549;
          border: 1px solid #0652DD;
          border-top: 4px solid #0652DD;
          border-bottom: 1px solid #F5F9FE;
        }
      }
    }
  }
}

.table-plans {
  h5 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    color: #0652DD;
    // text-align: center;
    letter-spacing: .5px;
  }

  .modal-dialog {
    max-width: 1200px;
    margin: 5.75rem auto 1.75rem auto;
    transform: translate(0px, -50px);
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(245, 249, 254);

    &:hover {
      background-color: #e8f8ff;
    }
  }

  table {
    tr {
      &:hover {
        background-color: #e8f8ff;
      }

      th {
        padding: 16px 24px;
        border: 1px solid #dee2e6;
        border-bottom: 2px solid #8ab9ff;
        border-top: 1px solid #8ab9ff;
        width: 25%;
        color: #0652DD;
      }

      td {
        padding: 8px 24px;
        border: 1px solid #dee2e6;
        border-bottom: none;
      }
    }
  }

  .custom-select {
    font-size: 16px;
    font-weight: 600;
    // text-align: center;
    padding: 8px;

    option {
      line-height: 1.8;
      padding: 10px 10px 10px 10px !important;
      font-weight: 600;
      min-height: 45px;
    }
  }
}

.badge-prev {
  background-color: #E7F0FE;
  color: #3F87F5;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 50px;
}

.badge-trab {
  background-color: #FDEEEE;
  color: #EB5757;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 50px;
}

.badge-civ {
  background-color: #CFE5E2;
  color: #0E6F61;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 50px;
}

.badge-penal {
  background-color: #D4F0FC;
  color: #218FC0;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 50px;
}

.badge-tribut {
  background-color: #E5E2F4;
  color: #6B5ABF;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 50px;
}

.badge-banc {
  background-color: #FCF3DC;
  color: #b8922a;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 50px;
}

.small-caps {
  font-variant: small-caps;
}

.tooltip-plans-table {
  background-color: #c8d4ee;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: ease-in .2s;

  svg {
    fill: #FFFFFF;
    top: 3px;
    left: 3px;
    position: absolute;
  }

  &:hover,
  &:focus {
    background-color: #010549;
    transition: ease-in .2s;

    .tooltip-content {
      display: inline;
      transition: ease-in .2s;
    }
  }

  .tooltip-content {
    position: absolute;
    width: 240px;
    background-color: #010549;
    padding: 16px;
    font-size: 13px;
    text-align: left;
    line-height: 1.6;
    color: #FFFFFF;
    z-index: 1;
    right: 11px;
    top: 11px;
    display: none;
    border-radius: 4px;
    transition: ease-in .2s;
  }
}

.popular-plan {
  background-color: #0652DD;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  padding: 1px 8px;
  position: absolute;
  width: 123px;
  top: -15px;
  margin-left: calc(50% - 62px);
}

.black-friday {
  background-color: #000 !important;
  opacity: 0.9;
  color: #FFFFFF !important;

  .popular-plan {
    background-color: #FBDD29;
    color: #010549;
  }

  .image-between-cards img {
    max-width: 80%;
  }

  .slider:before {
    background-color: #000;
  }

  .card-title,
  .text-deep-blue,
  .text-decoration {
    color: #ffd900 !important
  }

  .cj-plan-price {
    color: #FFF !important;
  }
  .text-dark-blue {
    color: #FFFFFF !important;
  }

  .btn-yellow-light:hover {
    background-color: #e0a800 !important;
    border-color: #e0a800 !important;
  }

  .hide {
    display: none;
  }

}

@media screen and (max-width: 991px) {
  .plan-features {
    .headingtxt {
      h3 {
        font-size: 24px;
        line-height: 1.3;
      }
    }
  }

  .card-columns {
    column-count: 1;
  }

  .table-plans {
    .modal {
      padding-right: 0px !important;
      overflow: scroll;

      .modal-dialog {
        height: 100vh;
        max-width: fit-content;
        margin: 70px 0px 0px 0px;
        z-index: 2;
        transform: translate(0px, 0px);
        position: absolute;

        .modal-content {
          border: none !important;
          border-radius: 0px !important;
          background-color: #FFFFFF;

          .modal-header {
            position: sticky;
            left: 8px;
            width: max-content;

            .close {
              font-size: 3rem;
              left: 4px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 800px) and (min-width: 1024px) {

  #banner-plans h1 {
    font-size: 2.3rem;
  }

  #banner-plans .text-muted {
    padding-top: 0.5rem !important;
  }

  #price-cards > div:nth-child(1) > div.text-center.p-3.mt-3.border-bottom.mb-3 {
    margin-top: 0.5rem !important;
  }

}

@media screen and (max-width: 480px) {
  #banner-plans {
    margin-top: 21px;
    padding-top: 3.1em !important;
  }

  #banner-plans h1 {
    font-size: 1.67rem;
  }

  #banner-plans .text-muted {
    font-size: 1.1rem;
    padding-top: 0.5rem !important;
    margin-top: 0 !important;
  }
  
  .search-container {
    border-radius: 4px;
  }

  #lawyers-tab {
    font-size: 16px;
  }

  .black-friday {
    .image-between-cards {
      margin-left: -1rem !important;
      margin-top: -6rem !important;
    }

    .image-between-cards img {
      max-width: 80% !important;
    }
  }

  #price-cards {
    .card {
      margin-bottom: 2rem !important;
      padding-bottom: 0 !important;
      div.text-center.p-3.mt-3.border-bottom.mb-3 {
        margin-bottom: 0 !important;
      }

    }
  }
}
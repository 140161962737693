/*======================================
//--//-->   Home Foot Banner
======================================*/
.homefootbanner {
  .bannerimg {
    width: 100%;
    position: relative;
    text-align: center;
    padding-top: 0;

    img {
      width: 100%;
      max-height: inherit;
    }
  }

  .bannerarea {
    width: 100%;
    position: absolute;
    max-width: 1170px;
  }

  .bannercontent {

    h1,
    h2 {
      font-weight: 500;
      font-size: 26px;
      line-height: 1.6;
      color: #3B424D;
    }
  }
}

/*======================================
//--//-->   Footer
======================================*/
.footer {
  background-color: #010549;
  padding-top: 6rem;
  content-visibility: auto;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  h5 {
    color: #3F87F5;
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }

  .menu {
    a {
      color: #fff;
      font-size: 16px;
      line-height: 24px;
    }

    a:hover {
      color: #3F87F5;
    }
  }

  .social-media {
    a {
      display: inline-block;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #3F87F5;
      font-size: 20px;
      background-color: #3F87F5;
      color: #010549;
      text-align: center;
    }

    a:hover {
      color: #0652DD;
      background-color: #010549;
    }
  }

  .copy {
    font-size: 14px;
    line-height: 24px;
    color: #3F87F5;
  }
}

@media screen and (max-width: 991px) {
  .footer {
    padding-top: 4rem;

    .logo-footer {
      max-width: 180px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
    }

    h5 {
      font-size: 18px;
      line-height: 24px;
    }

    .menu {
      a {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .social-media {
      a {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 18px;
      }
    }

    .copy {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: 575px) {
  .footer {
    text-align: center;

    ul.menu {
      text-align: center;
    }

    .social-media {
      ul {
        justify-content: center;
      }
    }
  }
}
